import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getAbout } from '../actions/MainAction';
export class AboutPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    // console.log(lang, lang_id)
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        map: data?.map?data?.map:"",
        address: data?.address?data?.address:"",
        phone_number: data?.phone_number?data?.phone_number:"",
        whatsapp_number: data?.whatsapp_number?data?.whatsapp_number:"",
        email: data?.email?data?.email:"",
        facebook: data?.facebook?data?.facebook:"",
        instagram: data?.instagram?data?.instagram:"",
        youtube: data?.youtube?data?.youtube:"",
       
        languages:{}
        ,
        requiredsLang: [],
        requireds:[ "map",  "address",  "phone_number",  "whatsapp_number",  "email",   "facebook",  "instagram",   "youtube"],
        afterCallFunction:this.props.getAbout,
        afterParams:[],
        url:'admin-about'
      }
    })
  }

  componentDidMount(){
    this.props.getAbout()
  }
  render() {
    const {aboutData} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header btn={false} onClickBtn={this.openModal.bind(this, "", "", 1)} title="Haqqımızda" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            aboutData?
                <Card1 
                  onChangeLang={(id)=>this.openModal(aboutData, "", id)} 
                  title1={aboutData?.email}
                  deleteUrl={`admin-about/${aboutData?.id}`}
                  afterCallFunction={()=> this.props.getAbout()}
                  deleteBtn={false}
                />:null
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  aboutData: state.Data.aboutData
});
const mapDispatchToProps = {changeStateValue, getAbout}
export default connect(mapStateToProps, mapDispatchToProps)(AboutPage)




