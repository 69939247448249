import axios from "axios";
import { MAIN_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";

export const loginAction = (params) => async dispatch => {
    return await axios.post(`${MAIN_API}/login`, params)
    .then(resp=>{
        localStorage.setItem("loginToken", resp?.data?.token)
        dispatch(changeStateValue({name:"user", value: resp?.data?.user}))

        return "success";
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}

export const getLoginUser=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/user`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"user", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getLanguages=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-language`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"languages", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getMainSlider=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-main-slider?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"mainSliders", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCompanyAbout=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-company-about?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"companyAbout", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCostumers=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-costumer?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"costumers", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCounter=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-counter?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"counterData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getCostumerReviews=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-costumer-reviews?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"costumerReviews", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getFounder=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-founder?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"founderData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getWhyUs=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-why-us?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"whyUs", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getAbout=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-about?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"aboutData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getContent=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-content?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"contents", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getServices=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-services?lang_id=1`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"services", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getBlogs=(type)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-blog?lang_id=1&type=${type}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"blogs", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getServiceCategory=(service_id)=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-services-categories?lang_id=1&service_id=${service_id}`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"serviceCategories", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}
export const getVideos=()=>async dispatch => {
    return await axios.get(`${MAIN_API}/admin-videos`, {
        headers:{
            "Authorization":`Bearer ${localStorage.getItem("loginToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"videosData", value: resp?.data}))
        return "success";
    }).catch(err=>{
        return "error";
    })
}





export const insertNewData=(url, params)=> async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}))
    return await axios.post(`${MAIN_API}/${url}`, params, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("loginToken")}`}
    }).then(resp=>{
        console.log(resp.data)
        dispatch(changeStateValue({name: "loader", value:false}))
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        console.log(err.response)
        return "error"
    })
}
export const deleteData=(url)=> async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}))
    return await axios.delete(`${MAIN_API}/${url}`, {
        headers: { "Authorization": `Bearer ${localStorage.getItem("loginToken")}` }
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}))
        console.log(err.response)
        return "error"
    })
}