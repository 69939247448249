import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class MultiImage extends Component {
    getBase64(file) {
        return new Promise((res, rej) => {
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    getImages=(e)=>{
        let promises = []; // Store promises in an array
        let imgs = [...this.props.images];
        for(let i = 0; i < e.target.files.length; i++){
            let promise = this.getBase64(e.target.files[i])
            .then(url => {
                imgs.push({
                    id: '',
                    image_full_url: url,
                    image: e.target.files[i]
                });
            });

            promises.push(promise); // Store each promise
        }
        Promise.all(promises)
        .then(() => {
            this.props.changeStateValue({
                name: "mainModal.images",
                value: imgs
            });
            // console.log(imgs)
        })
        .catch(error => {
            
        });
        // console.log(imgs)
    }

    onDeleteBtn=(index, data)=>{
        let arr = [...this.props?.mainModal?.images];
        arr.splice(index, 1)
        this.props.changeStateValue({
            name: 'mainModal.images',
            value:arr
        });
        let deleteImgs = [...this.props.mainModal?.deleted_images];
        if(data?.id!=''){
            deleteImgs.push(data?.id);
        }
        this.props.changeStateValue({
            name: 'mainModal.deleted_images',
            value: deleteImgs
        });
    }
  render() {
    const {images} = this.props;
    return (
      <div className='pl-[20px] pr-[20px] mt-[20px]'>
        <div>
            <input type='file' onChange={this.getImages.bind(this)} multiple />
        </div>
        <div className='flex mt-[10px] gap-[10px] flex-wrap'>
            {
                images?.map((item, j)=>{
                    return(
                        <div key={j} className='flex flex-col gap-[10px] min-w-[70px]'>
                            <img className='w-[70px] h-[70px] rounded-[6px] ' src={item?.image_full_url} />
                            <button onClick={this.onDeleteBtn.bind(this, j, item)} className='bg-red-700 text-[11px] text-white rounded-[5px] p-[2px_4px]'>Delete</button>
                        </div>
                    )
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    mainModal: state.Data.mainModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MultiImage)
