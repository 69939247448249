export const initialState = {
    loader: false,
    loaderLong: false,
    user:'',
    modalData: {
        position:false
    },
    languages:[],
    mainSliders:[],
    companyAbout:'',
    costumers:[],
    costumerReviews:[],
    counterData:[],
    founderData:'',
    whyUs:[],
    aboutData:"",
    contents:[],
    services:[],
    blogs:[],
    serviceCategories:[],
    videosData:[]
}
