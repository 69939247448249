import React, { Component } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Input from './components/Input'
import DropSearch from './components/DropSearch'
import LangBtns from './components/LangBtns'
import { connect } from 'react-redux'
import Editor from './components/Editor'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import ImageInp from './components/ImageInp'
import VideoInp from './components/VideoInp'
import SvgInp from './components/SvgInp'
import MultiImage from './components/MultiImage'
import CompImgInp from './components/CompImgInp'
export class MainModal extends Component {
  changeTextValue=(value, name)=>{
    this.props.changeStateValue({
      name: name,
      value:value
    })
  }
  render() {
    const {languages, mainModal} = this.props;
    console.log(mainModal)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white rounded-[10px] shadow w-[800px] max-h-[70vh] overflow-auto'>
            <Header title="title" onCloseBtn={()=>{this.props.resetStateValue({name:"mainModal"})}} />
            {/* <LangBtns params={mainModal.languages} languages={languages} /> */}
            {mainModal?.images || mainModal?.images?.length === 0? <MultiImage images={mainModal?.images} />:null}
            <div className='grid-full-250 p-[20px]  relative z-3'>
            
                {mainModal?.image || mainModal?.image===""?<ImageInp />:null }
                {mainModal?.company_image || mainModal?.company_image===""?<CompImgInp />:null }
                {mainModal?.svg || mainModal?.svg===""?<SvgInp />:null }
                {mainModal?.video || mainModal?.video===""?<VideoInp />:null }
                {mainModal?.title || mainModal?.title === "" ? <Input required name={"mainModal.title"} value={mainModal?.title} title="Title" />:null }
                {mainModal?.pathTitle || mainModal?.pathTitle === "" ? <Input required name={"mainModal.pathTitle"} value={mainModal?.pathTitle} title="Path" />:null }
                {mainModal?.property || mainModal?.property === "" ? <Input required name={"mainModal.property"} value={mainModal?.property} title="property" />:null }
                {mainModal?.path || mainModal?.path === "" || mainModal?.path === 0? <DropSearch options={mainModal?.dropValue} required name={"mainModal.path"} value={mainModal?.path} title="Select a service path"  placeholder="Select a service path" />:null }
                {mainModal?.count || mainModal?.count === "" ? <Input required name={"mainModal.count"} value={mainModal?.count} title="Count" />:null }
                {mainModal?.map || mainModal?.map === "" ? <Input required name={"mainModal.map"} value={mainModal?.map} title="map" />:null }
                {mainModal?.address || mainModal?.address === "" ? <Input required name={"mainModal.address"} value={mainModal?.address} title="address" />:null }
                {mainModal?.phone_number || mainModal?.phone_number === "" ? <Input required name={"mainModal.phone_number"} value={mainModal?.phone_number} title="phone number" />:null }
                {mainModal?.whatsapp_number || mainModal?.whatsapp_number === "" ? <Input required name={"mainModal.whatsapp_number"} value={mainModal?.whatsapp_number} title="whatsapp number" />:null }
                {mainModal?.email || mainModal?.email === "" ? <Input required name={"mainModal.email"} value={mainModal?.email} title="email" />:null }
                {mainModal?.facebook || mainModal?.facebook === "" ? <Input required name={"mainModal.facebook"} value={mainModal?.facebook} title="facebook" />:null }
                {mainModal?.instagram || mainModal?.instagram === "" ? <Input required name={"mainModal.instagram"} value={mainModal?.instagram} title="instagram" />:null }
                {mainModal?.youtube || mainModal?.youtube === "" ? <Input required name={"mainModal.youtube"} value={mainModal?.youtube} title="youtube" />:null }
                {mainModal?.blog_id || mainModal?.blog_id === "" ? <DropSearch options={mainModal?.dropValue} required name={"mainModal.blog_id"} value={mainModal?.blog_id} title="Select a project"  placeholder="Select a project" />:null }


                {mainModal?.languages?.title || mainModal?.languages?.title === "" ? <Input required name={"mainModal.languages.title"} value={mainModal?.languages?.title} title="Title" />:null }
                {mainModal?.languages?.sub_title || mainModal?.languages?.sub_title === "" ? <Input required name={"mainModal.languages.sub_title"} value={mainModal?.languages?.sub_title} title="Sub title" />:null }
                {mainModal?.languages?.position || mainModal?.languages?.position === "" ? <Input required name={"mainModal.languages.position"} value={mainModal?.languages?.position} title="Position" />:null }
                {mainModal?.languages?.user_name || mainModal?.languages?.user_name === "" ? <Input required name={"mainModal.languages.user_name"} value={mainModal?.languages?.user_name} title="Username" />:null }
                {/* {mainModal?.languages?.text || mainModal?.languages?.text === "" ? <Input required name={"mainModal.languages.text"} value={mainModal?.languages?.text} title="Text" />:null } */}
                {/* <Input title="Title" /> */}
                {/* <DropSearch  /> */}
            </div>
           <div className='pl-[20px] pr-[20px]'>
           {
              mainModal?.languages?.text || mainModal?.languages?.text === ""?
              <Editor onChange={(value, name)=>this.changeTextValue(value, name)} value={mainModal?.languages?.text} name={"mainModal.languages.text"} />:null
            }
           {
              mainModal?.languages?.description || mainModal?.languages?.description === ""?
              <Editor required={true} onChange={(value, name)=>this.changeTextValue(value, name)} value={mainModal?.languages?.description} name={"mainModal.languages.description"} />:null
            }
           </div>
            <Footer mainModal={mainModal} />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  languages: state.Data.languages,
  mainModal: state.Data.mainModal,
});
const mapDispatchToProps = {changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MainModal)