import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getContent } from '../actions/MainAction';
export class ContentPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        property: data?.property?data?.property:"",
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getContent,
        afterParams:[],
        url:'admin-content'
      }
    })
  }

  componentDidMount(){
    this.props.getContent()
  }
  render() {
    const {contents} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Content" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            contents?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-content/${data?.id}`}
                  afterCallFunction={()=> this.props.getContent()}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  contents: state.Data.contents
});
const mapDispatchToProps = {changeStateValue, getContent}
export default connect(mapStateToProps, mapDispatchToProps)(ContentPage)



