import React, { Component } from 'react'

export class Loader extends Component {
  render() {
    return (
        <div className="background_shadow">
        <div className="loader"></div>
    </div> 
    )
  }
}

export default Loader