import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getLanguages } from '../actions/MainAction';
export class LanguagePage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    // console.log(lang, lang_id)
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        title: data?.title?data?.title:"",
        pathTitle: data?.path?data?.path:'',
       
        languages:{}
        ,
        requiredsLang: [],
        requireds:[ "title"],
        afterCallFunction:this.props.getLanguages,
        afterParams:[],
        url:'admin-language'
      }
    })
  }

  componentDidMount(){
    this.props.getLanguages()
  }
  render() {
    const {languages} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header btn={true} onClickBtn={this.openModal.bind(this, "", "", 1)} title="Dil" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
           {
            languages?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  onChangeLang={(id)=>this.openModal(data, "", id)} 
                  title1={data?.title}
                  deleteUrl={`admin-language/${data?.id}`}
                  afterCallFunction={()=> this.props.getLanguages(this.props.type)}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  languages: state.Data.languages
});
const mapDispatchToProps = {changeStateValue, getLanguages}
export default connect(mapStateToProps, mapDispatchToProps)(LanguagePage)






