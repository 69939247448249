import React, { useEffect } from 'react'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import SideBar from './components/sidebar/SideBar'
import { Route, Routes, useNavigate } from 'react-router-dom'
import MainModal from './components/modals/MainModal'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './components/Loader'
import { getLoginUser } from './actions/MainAction'
import CompanyAboutPage from './pages/CompanyAboutPage'
import CostumerPage from './pages/CostumerPage'
import CostumerReviewsPage from './pages/CostumerReviewsPage'
import CounterPage from './pages/CounterPage'
import FounderPage from './pages/FounderPage'
import WhyUsPage from './pages/WhyUsPage'
import AboutPage from './pages/AboutPage'
import ContentPage from './pages/ContentPage'
import ServicesPage from './pages/ServicesPage'
import BlogPage from './pages/BlogPage'
import ProjectsPage from './pages/ProjectsPage'
import ServiceCategory from './pages/ServiceCategory'
import NewsPage from './pages/NewsPage'
import VideosPage from './pages/VideosPage'
import Profile from './pages/Profile'
import LanguagePage from './pages/LanguagePage'

function App() {
  const loader = useSelector(state=>state.Data.loader)
  const mainModal = useSelector(state=>state.Data.mainModal)
  const user = useSelector(state=> state.Data.user);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getLoginUser())
    .then(resp=>{
      if(resp == "error"){
        navigate("/login")
      }
    })
  },[])
  return (
    <div className='flex'>
      {user !=""? <SideBar /> : null}
      
      <Routes>
        <Route path='/login' element={ <LoginPage />} />
        <Route path='/' element={ <MainPage />} />
        <Route path='/company-about' element={ <CompanyAboutPage />} />
        <Route path='/costumers' element={ <CostumerPage type="costumer" />} />
        <Route path='/partners' element={ <CostumerPage  type="partner" />} />
        <Route path='/costumer-reviews' element={ <CostumerReviewsPage/>} />
        <Route path='/counter-main' element={ <CounterPage type="main"/>} />
        <Route path='/counter-about' element={ <CounterPage type="about"/>} />
        <Route path='/founder' element={ <FounderPage/>} />
        <Route path='/why-us' element={ <WhyUsPage/>} />
        <Route path='/about' element={ <AboutPage/>} />
        <Route path='/content' element={ <ContentPage/>} />
        <Route path='/services' element={ <ServicesPage/>} />
        <Route path='/blogs' element={ <BlogPage type="blog"/>} />
        <Route path='/news' element={ <NewsPage type="news"/>} />
        <Route path='/projects' element={ <ProjectsPage type="project"/>} />
        <Route path='/videos' element={ <VideosPage />} />
        <Route path='/profile' element={ <Profile />} />
        <Route path='/language' element={ <LanguagePage />} />
        <Route path='/service/:id/:company_name' element={ <ServiceCategory/>} />
      </Routes>
      {mainModal?.position? <MainModal /> :null}
      {loader? <Loader />:null}
    </div>
  )
}

export default App