import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getCostumerReviews, getBlogs } from '../actions/MainAction';
export class CostumerReviewsPage extends Component {
  getDropValue=(data)=>{
    let arr = [];
    for(const x of data){
      arr.push({
        label: x.translate?.title,
        value: x.id
      })
    }
    return arr;
  }
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
        blog_id: data?.blog_id?data?.blog_id:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            sub_title:lang?.sub_title?lang?.sub_title:'',
            description:lang?.description?lang?.description:'',
            position:lang?.position?lang?.position:'',
            user_name:lang?.user_name?lang?.user_name:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title", "sub_title", "description", "position", "user_name"],
        requireds:["blog_id"],
        afterCallFunction:this.props.getCostumerReviews,
        afterParams:[],
        dropValue:this.getDropValue(this.props.blogs),
        url:'admin-costumer-reviews'
      }
    })
  }

  componentDidMount(){
    this.props.getCostumerReviews()
    this.props.getBlogs("project")
  }
  render() {
    const {costumerReviews} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Rəylər" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            costumerReviews?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-costumer-reviews/${data?.id}`}
                  afterCallFunction={()=> this.props.getCostumerReviews()}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  costumerReviews: state.Data.costumerReviews,
  blogs: state.Data.blogs,
});
const mapDispatchToProps = {changeStateValue, getCostumerReviews, getBlogs}
export default connect(mapStateToProps, mapDispatchToProps)(CostumerReviewsPage)
