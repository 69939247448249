import React, { Component } from 'react'
import LoginEmailSvg from '../components/svg/LoginEmailSvg';
import LoginPasswordSvg from '../components/svg/LoginPasswordSvg';
import EyeSvg from '../components/svg/EyeSvg';
import EyeCloseSvg from '../components/svg/EyeCloseSvg';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import { loginAction } from '../actions/MainAction';
import { mixinAlert } from '../actions/SweetAlerts';
export class LoginPage extends Component {
    state={
        username:'',
        password:'',
        btnDisable:false,
        loginError:false,
        type:"password"
    }
    
    loginBtn(email, password, e){
      e.preventDefault()
      if(email !=="" && password !==""){
        this.props.loginAction({email, password})
        .then(resp=>{
          if(resp==="success"){
            this.props.navigate("/")
          }else{
            mixinAlert("error","Email or password is incorrect")
          }
        })
      }
    }
  render() {
    const {username, password, btnDisable, loginError, type} = this.state;
    return (
        <div className='flex bg-[#f5f5f5] items-center justify-center min-h-screen w-full mb-[-120px]'>
        <div className='w-[510px] bg-white pt-[44px] pl-[54px] pr-[54px] max-[660px]:pl-[20px]  max-[660px]:pr-[20px]  pb-[50px] rounded-[12px] shadow mt-[100px] max-[520px]:ml-[20px] max-[520px]:mr-[20px]'>
          <div className='flex justify-center'>
            <h2 className='text-[30px] font-semibold txt-blue'>Sign In</h2>
          </div>
          <form className='mt-[35px]'>
              <div className='flex flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginEmailSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='email' id='loginEmail' placeholder='Email' value={username} onChange={(e)=>this.setState({username: e.target.value})} />         
              </div>
              <div className='flex mt-[15px] justify-between flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginPasswordSvg />
                <input className='bg-white outline-none w-[80%] h-[40px] rounded-full p-2 text-[14px]' type={type} id='loginPassword' placeholder='Password' value={password} onChange={(e)=>this.setState({password: e.target.value})} />         
                <div className='cursor-pointer' onClick={(e)=>{e.preventDefault(); this.setState({type: type==="text"?"password":"text"})}}>
                  {type==="password"? <EyeSvg />: <EyeCloseSvg />}
                </div>
              </div>
              
              <button disabled={btnDisable} onClick={this.loginBtn.bind(this, username, password)} className='mt-[33px] bg-blue-700 w-full h-[56px] font-bold text-[16px] rounded-[12px] text-white '>Log In</button>
              {
                loginError?
                <p className='text-red-600 text-[12px] mt-2'>Email or Password is wrong!</p>:null
              }
          </form>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {loginAction}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(LoginPage))