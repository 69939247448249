import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getFounder } from '../actions/MainAction';
export class FounderPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    // console.log(lang, lang_id)
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            text:lang?.text?lang?.text:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title", "description", "text"],
        requireds:[],
        afterCallFunction:this.props.getFounder,
        afterParams:[],
        url:'admin-founder'
      }
    })
  }

  componentDidMount(){
    this.props.getFounder()
  }
  render() {
    const {founderData} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header btn={true} onClickBtn={this.openModal.bind(this, "", "", 1)} title="Founder haqqında" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            founderData?
                <Card1 
                  image={founderData?.image_full_url}
                  onChangeLang={(id)=>this.openModal(founderData, founderData.translates.find(x=>x.lang_id==id), id)} 
                  title1={founderData?.translate?.title}
                  deleteUrl={`admin-founder/${founderData?.id}`}
                  afterCallFunction={()=> this.props.getFounder()}
                  deleteBtn={false}
                />:null
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  founderData: state.Data.founderData
});
const mapDispatchToProps = {changeStateValue, getFounder}
export default connect(mapStateToProps, mapDispatchToProps)(FounderPage)


