import React, { Component } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux';
import { insertNewData } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
import Input from '../components/modals/components/Input';
import { mixinAlert } from '../actions/SweetAlerts';
export class Profile extends Component {
  state={
    btnDisable:false
  }
  upDateBtn(user){
    if(user?.email !==""){
      let formData = new FormData();
      for(const property in user){
        formData.append(property, user[property])
      }
      formData.append("_method", "put");
      this.setState({btnDisable:true})
      this.props.insertNewData(`admin-user/${user?.id}`, formData)
      .then(resp=>{
        this.setState({btnDisable:false})
        if(resp === "success"){
          mixinAlert("success", "Updated successfully")
        }else{
          mixinAlert("error", "Something went wrong")
        }
      })
    }
  }
  render() {
    const {user} = this.props;
    const {btnDisable} = this.state;
    return (
        <div className='p-[40px] w-full'>
            <Header btn={false} title={"Xəbərlər"} />
            <div className='grid-full-250 grid grid-cols-2 mt-[20px] bg-white p-[20px] shadow rounded-[10px]'>
              <Input required name={"user.email"} value={user.email} title="Email" />
              <Input type="password" required name={"user.password"} value={user.password} title="Password" />
              <button onClick={this.upDateBtn.bind(this, user)} disabled={btnDisable} className='bg-blue-600 text-white rounded-[7px] text-[13px]'>Update</button>
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
});
const mapDispatchToProps = {insertNewData, changeStateValue}
export default connect(mapStateToProps,mapDispatchToProps)(Profile)
