import React, { Component } from 'react'
import LangBtns from '../modals/components/LangBtns'
import { connect } from 'react-redux';
import { deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { Link } from 'react-router-dom';
export class Card1 extends Component {
  onClickDelete=(url)=>{
    // deleteData
    confirmAlert("warning", "Əminsiniz ?", "Bu faylı geri qaytara bilməyəcəksiniz?")
    .then(respAlert=>{
      if(respAlert === "success"){
        this.props.deleteData(url)
        .then(resp=>{
          if(resp==="success"){
            this.props.afterCallFunction()
            mixinAlert("success", "Data uğurla silindiu")
          }else{
            mixinAlert("error", "Xəta baş verdi")
          }
        })
      }
    })
  }
  render() {
    const {onChangeLang=()=>{}, title1="", deleteUrl="", image="", video="", deleteBtn=true, url="", langBtn=true} = this.props;
    return (
      <div className='bg-white shadow rounded-[10px] p-[10px]'>
        {
          image !=""?
          <img src={image} className='w-full h-[140px] rounded-[12px]' />:null
        }
        {
          video !=""?
          <video controls src={video} className='w-full h-[140px] rounded-[12px]' />:null
        }
        <ul className='flex flex-col gap-[7px]'>
            {title1!=""? <li className='text-[14px] font-semibold text-gray-700'>{title1}</li>:null}
        </ul>
        {
          langBtn?
          <LangBtns onChangeLang={(id)=>onChangeLang(id)} />:null
        }
        
        <div className='flex justify-between mt-[10px] border-t pt-[7px]'>
          {
            deleteBtn?
            <button onClick={this.onClickDelete.bind(this, deleteUrl)} className='bg-red-800 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]'>Delete</button>:null
          }
          {
            url !=""?
            <Link className='bg-gray-600 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]' to={url}>Kateqoriyalar</Link>:null
          }
            {/* <button className='bg-gray-600 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]'>Info</button> */}
            {/* <button className='bg-blue-800 text-white text-[12px] font-semibold p-[2px_9px] rounded-[6px]'>Edit</button> */}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(Card1)