import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getWhyUs } from '../actions/MainAction';
export class WhyUsPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
        svg: data?.svg?data?.svg:"",
        svg_full_url: data?.svg_full_url?data?.svg_full_url:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title", "description"],
        requireds:[],
        afterCallFunction:this.props.getWhyUs,
        afterParams:[],
        url:'admin-why-us'
      }
    })
  }

  componentDidMount(){
    this.props.getWhyUs()
  }
  render() {
    const {whyUs} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Niye biz" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            whyUs?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-why-us/${data?.id}`}
                  afterCallFunction={()=> this.props.getWhyUs()}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  whyUs: state.Data.whyUs
});
const mapDispatchToProps = {changeStateValue, getWhyUs}
export default connect(mapStateToProps, mapDispatchToProps)(WhyUsPage)





