import React, { Component } from 'react'
import Logo from '../Logo'
import { NavLink } from 'react-router-dom'

export class SideBar extends Component {
  render() {
    return (
      <div className='min-w-[260px] w-[260px] h-screen bg-white'>
        <div className='w-[260px] h-screen bg-white overflow-auto fixed shadow'>
           <div className='mt-[20px]'>
                <Logo />
           </div>
           <nav>
            <ul className='pl-[20px] pr-[20px] mt-[40px] flex flex-col gap-[4px]'>
                <li >
                    <NavLink to={'/'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Əsas Slider</NavLink>
                </li>
                <li>
                    <NavLink to={'/company-about'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Şirkət haqqında</NavLink>
                </li>
                <li>
                    <NavLink to={'/costumers'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Müştərilər</NavLink>
                </li>
                <li>
                    <NavLink to={'/partners'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Tərəfdaşlar</NavLink>
                </li>
                <li>
                    <NavLink to={'/costumer-reviews'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Rəylər</NavLink>
                </li>
                <li>
                    <NavLink to={'/counter-main'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Əsas səhifə sayğac</NavLink>
                </li>
                <li>
                    <NavLink to={'/counter-about'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Haqqımızda səhifə sayğac</NavLink>
                </li>
                <li>
                    <NavLink to={'/founder'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Founder haqqında</NavLink>
                </li>
                <li>
                    <NavLink to={'/why-us'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Niye biz?</NavLink>
                </li>
                <li>
                    <NavLink to={'/about'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Haqqımızda</NavLink>
                </li>
                <li>
                    <NavLink to={'/content'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Content</NavLink>
                </li>
                <li>
                    <NavLink to={'/services'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Xidmətlər</NavLink>
                </li>
                <li>
                    <NavLink to={'/blogs'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Bloglar</NavLink>
                </li>
                <li>
                    <NavLink to={'/projects'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Layihələr</NavLink>
                </li>
                <li>
                    <NavLink to={'/news'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Xəbərlər</NavLink>
                </li>
                <li>
                    <NavLink to={'/videos'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Reklam çarxları</NavLink>
                </li>
                <li>
                    <NavLink to={'/language'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Dil</NavLink>
                </li>
                <li>
                    <NavLink to={'/profile'} className='bg-gray-100 rounded-[10px] p-[10px_12px] inline-block w-full text-[14px] font-semibold text-gray-600' href='#'>Profile</NavLink>
                </li>
            </ul>
           </nav>
        </div>
      </div>
    )
  }
}

export default SideBar