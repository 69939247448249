import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getServiceCategory } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
export class ServiceCategory extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        service_id: this.props.params.id,
        id: data?.id?data?.id:"",
        svg: data?.svg?data?.svg:"",
        svg_full_url: data?.svg_full_url?data?.svg_full_url:"",
       
        languages:
        {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            lang_id:lang_id
        },
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getServiceCategory,
        afterParams:[this.props.params.id],
        url:'admin-services-categories'
      }
    })
  }

  componentDidMount(){
    this.props.getServiceCategory(this.props.params.id)
  }
  render() {
    const {serviceCategories} = this.props;
    console.log(serviceCategories)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title={this.props.params.company_name} />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            serviceCategories?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.svg_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-services-categories/${data?.id}`}
                  afterCallFunction={()=> this.props.getServiceCategory(this.props.params.id)}
                //   url={`/service/${data?.id}`}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  serviceCategories: state.Data.serviceCategories
});
const mapDispatchToProps = {changeStateValue, getServiceCategory}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ServiceCategory))

