import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getBlogs } from '../actions/MainAction';
export class ProjectsPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        company_image: data?.company_image?data?.company_image:"",
        type: this.props.type,
        company_image_full_url: data?.company_image_full_url?data?.company_image_full_url:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        image_count:0,
        deleted_images:[],
        images:data?.gallery?data?.gallery:[],
        requiredsLang: ["title", "description"],
        requireds:[],
        afterCallFunction:this.props.getBlogs,
        afterParams:[this.props.type],
        url:'admin-blog'
      }
    })
  }

  componentDidMount(){
    this.props.getBlogs(this.props.type)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.type != this.props.type){
        this.props.getBlogs(this.props.type)
    }
  }
  render() {
    const {blogs} = this.props;
    console.log(blogs)
    return (
      <div className='p-[40px] w-full'>
        <Header  onClickBtn={this.openModal.bind(this, "", "", 1)} title={this.props.type === "blog"?"Bloglar":"Layihələr"} />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
        {
            blogs?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.company_image_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-blog/${data?.id}`}
                  afterCallFunction={()=> this.props.getBlogs(this.props.type)}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  blogs: state.Data.blogs
});
const mapDispatchToProps = {changeStateValue, getBlogs}
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage)






