import React, { Component } from 'react'

export class LoginPasswordSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={20}
        fill="none"
      >
        <path
          stroke="#64748B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M13 9H3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2Z"
        />
        <path
          stroke="#64748B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
        />
        <path
          stroke="#64748B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M4 9V5a4 4 0 0 1 8 0v4"
        />
      </svg>
    )
  }
}

export default LoginPasswordSvg