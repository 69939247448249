import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getServices } from '../actions/MainAction';
export class ServicesPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        svg: data?.svg?data?.svg:"",
        svg_full_url: data?.svg_full_url?data?.svg_full_url:"",
       
        languages:
        {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            sub_title:lang?.sub_title?lang?.sub_title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
        },
        image_count:0,
        deleted_images:[],
        requiredsLang: ["title", "sub_title", "description"],
        images:data?.images?data?.images:[],
        requireds:[],
        afterCallFunction:this.props.getServices,
        afterParams:[],
        url:'admin-services'
      }
    })
  }

  componentDidMount(){
    this.props.getServices()
  }
  render() {
    const {services} = this.props;
    // console.log(services)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Xidmətlər" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            services?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.svg_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-services/${data?.id}`}
                  afterCallFunction={()=> this.props.getServices()}
                  url={`/service/${data?.id}/${data?.translate?.title}`}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  services: state.Data.services
});
const mapDispatchToProps = {changeStateValue, getServices}
export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage)