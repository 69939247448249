import React, { Component } from 'react'

export class EyeCloseSvg extends Component {
  render() {
    const {color="#1C274C"} = this.props

    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M15.649 5.395A10.23 10.23 0 0 0 12 4.72c-3.53 0-6.82 1.829-9.11 4.994-.9 1.24-.9 3.323 0 4.562.455.629.949 1.205 1.477 1.724m14.958-8.304a13.464 13.464 0 0 1 1.785 2.018c.9 1.24.9 3.323 0 4.562-2.29 3.165-5.58 4.994-9.11 4.994-1.386 0-2.734-.282-4-.816"
        />
        <path
        fill={color}
        fillRule="evenodd"
        d="M17.742 5.58c-1.746-1.042-3.7-1.61-5.742-1.61-3.804 0-7.307 1.973-9.717 5.303-.562.774-.818 1.769-.818 2.722 0 .953.256 1.947.817 2.721a13.95 13.95 0 0 0 2.535 2.69l3.908-3.576A3.74 3.74 0 0 1 8.25 12 3.747 3.747 0 0 1 12 8.25c.783 0 1.511.24 2.113.65l3.629-3.32ZM6.068 18.295C7.86 19.41 9.884 20.02 12 20.02c3.804 0 7.307-1.973 9.717-5.303.562-.775.818-1.769.818-2.722 0-.953-.256-1.947-.817-2.721a13.885 13.885 0 0 0-2.705-2.824L6.068 18.295ZM15 11.25a.75.75 0 0 1 .75.75A3.747 3.747 0 0 1 12 15.75a.75.75 0 0 1 0-1.5A2.247 2.247 0 0 0 14.25 12a.75.75 0 0 1 .75-.75ZM22.553 2.194a.75.75 0 0 1-.047 1.06l-3.205 2.932a.75.75 0 1 1-1.012-1.107l3.205-2.932a.75.75 0 0 1 1.06.047ZM5.731 17.586a.75.75 0 0 1-.047 1.06l-3.178 2.907a.75.75 0 0 1-1.012-1.106l3.178-2.908a.75.75 0 0 1 1.06.047Z"
        clipRule="evenodd"
        />
    </svg>
    )
  }
}

export default EyeCloseSvg