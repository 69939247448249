import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getMainSlider, getServices } from '../actions/MainAction';
export class MainPage extends Component {
  getDropValue=(value=[])=>{
    let arr = [];
    for(let i = 0; i < value.length; i++){
      arr.push({
        label: value[i].translate?.title,
        value: i
      })
    }
    return arr;
  }
  openModal=(data, lang, lang_id=1)=>{
    console.log(lang, lang_id)
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
        video: data?.video?data?.video:"",
        video_full_url: data?.video_full_url?data?.video_full_url:"",
        path: data?.path?data?.path:"",
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            text:lang?.text?lang?.text:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title"],
        requireds:[],
        afterCallFunction:this.props.getMainSlider,
        afterParams:[],
        dropValue: this.getDropValue(this.props.services),
        url:'admin-main-slider'
      }
    })
  }

  componentDidMount(){
    this.props.getMainSlider();
    this.props.getServices()
  }
  render() {
    const {mainSliders} = this.props;
    // console.log(mainSliders)
    return (
      <div className='p-[40px] w-full'>
        <Header onClickBtn={this.openModal.bind(this, "", "", 1)} title="Əsas slider" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            mainSliders?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-main-slider/${data?.id}`}
                  afterCallFunction={()=> this.props.getMainSlider()}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  mainSliders: state.Data.mainSliders,
  services: state.Data.services,
});
const mapDispatchToProps = {changeStateValue, getMainSlider, getServices}
export default connect(mapStateToProps, mapDispatchToProps)(MainPage)