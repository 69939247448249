import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getCompanyAbout } from '../actions/MainAction';
export class CompanyAboutPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    // console.log(lang, lang_id)
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        image_full_url: data?.image_full_url?data?.image_full_url:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title", "description"],
        requireds:[],
        afterCallFunction:this.props.getCompanyAbout,
        afterParams:[],
        url:'admin-company-about'
      }
    })
  }

  componentDidMount(){
    this.props.getCompanyAbout()
  }
  render() {
    const {companyAbout} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header btn={false} onClickBtn={this.openModal.bind(this, "", "", 1)} title="Şirkət haqqında" />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
          {
            companyAbout?
                <Card1 
                  image={companyAbout?.image_full_url}
                  video={companyAbout?.video_full_url}
                  onChangeLang={(id)=>this.openModal(companyAbout, companyAbout.translates.find(x=>x.lang_id==id), id)} 
                  title1={companyAbout?.translate?.title}
                  deleteUrl={`admin-company-about/${companyAbout?.id}`}
                  afterCallFunction={()=> this.props.getCompanyAbout()}
                  deleteBtn={false}
                />:null
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  companyAbout: state.Data.companyAbout
});
const mapDispatchToProps = {changeStateValue, getCompanyAbout}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyAboutPage)
