import React, { Component } from 'react'
import Header from '../components/Header'
import Card1 from '../components/cards/Card1'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { getCostumers } from '../actions/MainAction';
export class CostumerPage extends Component {
  openModal=(data, lang, lang_id=1)=>{
    this.props.changeStateValue({
      name:"mainModal",
      value:{
        position:true,
        id: data?.id?data?.id:"",
        image: data?.image?data?.image:"",
        type: this.props.type,
        image_full_url: data?.image_full_url?data?.image_full_url:"",
       
        languages:
          {
            id:lang?.id?lang?.id:'',
            title:lang?.title?lang?.title:'',
            description:lang?.description?lang?.description:'',
            lang_id:lang_id
          }
        ,
        requiredsLang: ["title", "description"],
        requireds:[],
        afterCallFunction:this.props.getCostumers,
        afterParams:[this.props.type],
        url:'admin-costumer'
      }
    })
  }

  componentDidMount(){
    this.props.getCostumers(this.props.type)
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.type != this.props.type){
        this.props.getCostumers(this.props.type)
    }
  }
  render() {
    const {costumers} = this.props;
    return (
      <div className='p-[40px] w-full'>
        <Header  onClickBtn={this.openModal.bind(this, "", "", 1)} title={this.props.type === "costumer"?"Müştərilər":"Tərəfdaşlar"} />
        <div className='grid-full-250 grid grid-cols-2 mt-[20px]'>
        {
            costumers?.map((data, i)=>{
              return(
                <Card1 
                  key={i}
                  image={data?.image_full_url}
                  video={data?.video_full_url}
                  onChangeLang={(id)=>this.openModal(data, data.translates.find(x=>x.lang_id==id), id)} 
                  title1={data?.translate?.title}
                  deleteUrl={`admin-costumer/${data?.id}`}
                  afterCallFunction={()=> this.props.getCostumers(this.props.type)}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  costumers: state.Data.costumers
});
const mapDispatchToProps = {changeStateValue, getCostumers}
export default connect(mapStateToProps, mapDispatchToProps)(CostumerPage)

